import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BadgeModule } from 'primeng/badge';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';
import { TooltipModule } from 'primeng/tooltip';
import { CoreModule } from 'src/app/core/core.module';
import { SharedModule } from 'src/app/main/shared/shared.module';
import { AppConfigModule } from '../../config/app.config.module';
import { AppMenuComponent } from '../menu/app.menu.component';
import { AppMenuitemComponent } from '../menuItem/app.menuitem.component';
import { AppProfileSidebarComponent } from '../profileSidebar/app.profilesidebar.component';
import { AppSidebarComponent } from '../sidebar/app.sidebar.component';
import { AppLayoutComponent } from './app.layout.component';

@NgModule({
  declarations: [AppLayoutComponent, AppSidebarComponent, AppProfileSidebarComponent, AppMenuComponent, AppMenuitemComponent],
  imports: [
    FormsModule,
    InputTextModule,
    SidebarModule,
    BadgeModule,
    RadioButtonModule,
    InputSwitchModule,
    TooltipModule,
    RippleModule,
    RouterModule,
    AppConfigModule,
    TranslateModule,
    CoreModule,
    SharedModule,
  ],
})
export class AppLayoutModule {}
